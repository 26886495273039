
export const DASHBOARD = "/dashboard"
export const LOGIN = "/login"
export const LOGOUT = "/logout"
export const ROUTE_ROOM_CREATE = "/dashboard/rooms/create";
export const ROUTE_ROOM_UPDATE = "/dashboard/rooms/update/:roomId";

export const ROUTE_RESERVATION_CREATE = "/dashboard/reservations/create";
export const ROUTE_RESERVATION_UPDATE = "/dashboard/reservations/update/:reservationId";

export const localStorageLocation = 'vr_user'

export const messages = {
    rooms: {
        createButton: "Dodaj pokój",
        updateButton: "Edytuj pokój",
        create: "Pokój został zapisany w bazie danych",
        update: "Pokój został zaktualizowany w bazie danych"
    },
    reservations: {
        notSelectedDates: "Wybierz zakres dat, aby zobaczyć dostępne pokoje",
        selectedDates: "Kliknij, aby wybrać pokój",
        createButton: "Dodaj rezerwację",
        updateButton: "Edytuj rezerwację",
        create: "Rezerwacja została zapisana w bazie danych",
        update: "Rezerwacja została zaktualizowana w bazie danych"
    },
    invoices: {
        create: "Faktura została zapisana w bazie danych. Możesz ją pobrać przechodząc do listy faktur",
        items: {
            pl: {
                selectText: "Wybierz pozycję z listy",
                itemText: "Usługa hotelowa - pokój %number%-os",
                otherItemText: "Inne",
                cateringItemText: "Usługa cateringowa"
            },
            en: {
                selectText: "Select position from list",
                itemText: "Hotel Service - %number% person room",
                otherItemText: "Other",
                cateringItemText: "Catering service"
            }
        }
    },
    errors: {
        validation: "Dane w formularz są niepoprawne, sprawdź je",
        roomNameAlreadyTaken: "Pokój o wybranej nazwie istnieje już w bazie danych",
        roomAlreadyReserved: "Jeden z wybranych pokoi na liście nie jest już dostępny w wybranym terminie",
        missingApiToken: "Brak tokenu potwierdzającego zapytanie do API",
        invalidPhoneNumber: "Numer telefonu jest niepoprawny, użyj formatu z +48 lub innym prefixem"
    },
    authorization: {
        invalidLogin: "Nieprawidłowy login lub hasło!"
    },
    reports: {
        emptyDataWithForm: "Brak rezerwacji w tym dniu, wybierz inną datę",
        emptyDataWithoutForm: "Brak rezerwacji w tym dniu, przejdź do zakładki \"Raporty\" i wybierz inną datę"
    }
}